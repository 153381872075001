import React from "react"
import { Col, Button, Form, Spinner } from 'react-bootstrap'
import Recaptcha from 'react-google-recaptcha'
import axios from 'axios'

import Layout from "../components/layout"
import SEO from "../components/seo"

class RegistrationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      phone: '',
      recaptcha: '',
      loading: false,
      formControls: {
        phone: '',
        email: '',
        name: '',
      }
    }
    this.verifyCallback = this.verifyCallback.bind(this);
  }
  // Jump to top of window
  componentDidMount() {
      window.scrollTo(0, 0);
  }

  async changeHandler(event) {
    let name = event.target.name;
    let value = event.target.value;

    await this.setState({
      formControls: {
          ...this.state.formControls,
          [name]: value
      }
    });
  }

  // String insert function
  insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // Prevent the default submit action
      event.preventDefault();
      event.stopPropagation();
    }
    await this.setState({ validated: true });

    this.setState({loading: true})
    console.log(this.state.formControls)
    axios.post(`${ process.env.REACT_APP_API_URL }/email/coastal`, this.state.formControls)
      .then(response => {
        var success = response.data.success;
        if(success === true){
          this.setState({loading: false});
          document.getElementById('send').innerHTML = 'Message Sent!';
          alert('Email sent successfully. We\'ll be in touch with you soon.');
        } else if(success === false){
          this.setState({loading: false});
          document.getElementById('send').innerHTML = 'Failure Sending!';
          alert('Failed to send email. Please try again.');
        }
        
      }).catch(err => {
        this.setState({loading: false});
        alert('Failed to send email. Please try again.');
      })
  }

  validatePhone(input) {
      // Check if input length is less than or greater than state number
      var number = input.target.value;

      // If number.length == 10 (coming from autofill)
      if(number.length === 10 && !number.includes('-')){
          number = this.insert(number, 3, '-');
          number = this.insert(number, 7, '-');
      }

      // If number.length is less than the state, user is deleting and state should be updated
      if (number.length < this.state.phone.length){
        this.setState({phone: number});
        this.changeHandler(input);
      }

      // If greater, add to state as long as number.length < 13
      else if(number.length < 13 && number.length > this.state.phone.length){
        if(number.length === 3 || number.length === 7) {
            number = number + '-';
        }
        this.setState({phone: number});
        this.changeHandler(input)
      }
  }

  validateEmail(input) {
      var email = input.target.value;
      var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if(re.test(email) === true){
          document.getElementById('formGridEmail').style.border = '';
          this.changeHandler(input);
      } else {
          document.getElementById('formGridEmail').style.border = '2px solid red';
      }
      return re.test(email);
  }

  verifyCallback(response) {
    this.setState({recaptcha: response});
  };

  render() {
    return(
      <Layout location={this.props.location}>
        <SEO title="Home" description="hello world"/>
        <h1 style={styles.header}>Mailing List Sign Up</h1>
        <div style={styles.body}>
          <p>
            Welcome to The Coastal Advisory Group sign-up page. We’re glad you want to know more about our wealth management expertise, and what we can do to make your financial journey pleasant sailing.
          </p>
          <p>
          <span style={styles.secondaryText}>
            Our Privacy Policy<br/>
          </span>
            Your privacy stays here with us—just like all your important information. We do not share your contact information with anyone, no more than we would share our client’s account information. Trust is the basis of all good things. You can trust The Coastal Advisory Group.
          </p>
              <Form className="Contact--form" onSubmit={e => this.handleSubmit(e)}>
                <Form.Row>
                  <Form.Group as={Col} lg={4} md={4} sm={12} xs={12} controlId="formGridName">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control required name="name" type="text" placeholder="Enter Your Name" onChange={e => this.changeHandler(e)} />
                  </Form.Group>

                  <Form.Group as={Col} lg={4} md={4} sm={12} xs={12} controlId="formGridEmail">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control type="email" name="email" onChange={e => this.validateEmail(e)} placeholder="Enter Email" required />
                  </Form.Group>

                  <Form.Group as={Col} lg={4} md={4} sm={12} xs={12} controlId="formGridPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="tel" name="phone" placeholder="(xxx)-xxx-xxxx" value={this.state.phone} onChange={e => this.validatePhone(e)} />
                  </Form.Group>
                </Form.Row>
                <div className="text-center">
                  <Recaptcha className="recaptcha-box--home" sitekey="6Lcs6LIUAAAAAOQPRGwzyFuHjj0BNSrgUdL9JxAi" onChange={this.verifyCallback}/> <br/>
                  <Button id="send" className="btn btn-disabled" name="send" type="submit" disabled={!(this.state.recaptcha)}>
                    {
                      (this.state.loading === true)
                      ? <Spinner animation="border" variant="light" />
                      : 'Send Message'
                    }
                  </Button>
                </div>
              </Form>
    
        </div>
      </Layout>
    )
  }
}

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default RegistrationPage
